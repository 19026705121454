<template>
  <b-card>
    <div class="card-title">
      <b-form-group label-for="production-weeks">
        <b-form-select
          id="production-weeks"
          v-model="period"
          size="lg"
          @change="updateFilters"
        >
          <b-form-select-option
            v-for="option in constants.WEEKS"
            :key="option.start_at"
            :value="option.start_at"
          >
            Semaine {{ option.week_of_year }}
            ( du
            {{ $dayjs(option.start_at).format('DD.MM.YYYY') }} au
            {{ $dayjs(option.end_at).format('DD.MM.YYYY') }}
            <span v-if="option.is_current == true">- cette semaine</span>
            )
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>
    <div>
      <h4 class="mb-1">Progression de la sélection des menus</h4>
      <div class="row my-0 py-0">
        <div class="col-md-8">
          <div class="mb-4">
            <b-progress :max="metrics.total" height="22px" show-value>
              <b-progress-bar
                :value="metrics.none"
                variant="warning"
              ></b-progress-bar>
              <b-progress-bar
                :value="metrics.partial"
                variant="info"
              ></b-progress-bar>
              <b-progress-bar
                :value="metrics.complete"
                variant="success"
              ></b-progress-bar>
            </b-progress>
            <p>
              Nombre de sélection actives totales : {{ metrics.total }} -
              complétées : {{ metrics.complete }} - partielles :
              {{ metrics.partial }}
            </p>
          </div>
        </div>
        <div class="col-md-4 text-right px-0">
          <button
            v-if="$store.getters['user/profile'].role == 'ADMINISTRATOR'"
            class="btn btn-primary btn-icon mr-1"
            @click.prevent="init(true)"
          >
            <font-awesome-icon icon="sync" />
            Mettre à jour
          </button>
        </div>
      </div>
      <b-overlay :show="loading" rounded="sm">
        <b-tabs v-model="tabIndex" content-class="mt-2">
          <b-tab :title="`Taches (${tasks.length})`" active>
            <production-tasks :tasks="tasks" :display-task-total="true" />
            <p
              v-if="tasks.length == 0 && productions_batches.length == 0"
              class="text-center text-muted"
            >
              <em>Aucune tâche pour l’instant</em>
            </p>
          </b-tab>
          <b-tab
            v-if="meta.ingredients"
            :title="`Ingrédients (${meta.ingredients.length})`"
          >
            <recipe-ingredients-card
              :quantity="0"
              :ingredients="meta.ingredients"
              :display="[
                'label',
                'suplier',
                'product_code',
                'quantity',
                'quantity_for_quantity',
                'cost_for_quantity',
              ]"
            />
          </b-tab>
          <b-tab :title="`Sélections (${selections.length})`">
            <div class="my-1">
              <b-form-select
                v-model="selectionsState"
                @change="getselections()"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Tous les états
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="option in ['COMPLETE', 'PARTIAL', 'NONE']"
                  :key="option"
                  :value="option"
                >
                  {{ $t(`PRODUCTION.${option}`) }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <p>Nombre de sélections : {{ selectionsMeta.total }}</p>
            <production-selections :selections="selections" />
          </b-tab>
        </b-tabs>
      </b-overlay>
    </div>
    <!-- <pre>{{ metrics }}</pre> -->
    <!-- <pre>{{ tasks }}</pre> -->
    <!-- <pre>{{ meta }}</pre> -->
    <!-- <pre>{{ productions_batches }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
    <!-- <pre>{{ selections }}</pre> -->
  </b-card>
</template>

<script>
import {
  BProgress,
  BProgressBar,
  BCard,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BOverlay,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import RecipeIngredientsCard from '@/components/RecipeIngredientsCard.vue'
import ProductionTasks from '@/components/ProductionTasks.vue'
import ProductionSelections from '@/components/ProductionSelections.vue'

const productionsTasksFields =
  'results{id,status,source,production_batch_id,quantity,due_at,recipe{label}},meta'

const userSelectionFields =
  'results{' +
  'id,limit,updated_at,user_subscription_id,recipes_relations_quantities,choice,' +
  'user{id,fullname}' +
  'subscription{status,payment_service}' +
  '},meta'

export default {
  components: {
    BProgress,
    BProgressBar,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BOverlay,
    BTabs,
    BTab,
    RecipeIngredientsCard,
    ProductionTasks,
    ProductionSelections,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      displayFilters: true,
      displayForm: false,
      selectAll: false,
      constants: {},
      period: 0,
      productions_batches: [],
      tasks: [],
      selections: [],
      selectionsMeta: {},
      selectionsState: null,
      metrics: {},
      meta: {},
      pagination_batches: { total: 0 },
      pagination_tasks: { total: 0 },
      filters: {
        page: 1,
        source: 'USER_SELECTION',
        status: ['FORECAST', 'PENDING', 'ASSIGNED'],
        per_page: 100,
        start_at: null,
        end_at: null,
        merge_ingredients: true,
      },
    }
  },
  computed: {
    title() {
      if (this.period && this.constants.WEEKS) {
        const week = this.getPeriod(this.period)
        return `Semaine ${week.week_of_year} - ${this.$dayjs(
          this.period
        ).format('YYYY')}`
      }
      return '…'
    },
    syncDisabledAt() {
      if (this.period && this.constants.WEEKS) {
        // const week = this.getPeriod(this.period)
        return this.$dayjs(this.period).subtract(8, 'day')
      }
      return '…'
    },
    syncDisabled() {
      if (this.period && this.constants.WEEKS) {
        if (this.$dayjs() <= this.syncDisabledAt) {
          return false
        }
      }
      return true
    },
  },
  watch: {
    $route() {
      this.init()
    },
    period(value) {
      const week = this.getPeriod(value)
      if (week) {
        this.filters.start_at = week.start_at
        this.filters.end_at = week.end_at
        localStorage.LEONETMARCEL_PERIOD = value
      }
    },
    tasks(value) {
      if (
        value.length !==
        this.tasks.filter((task) => task.status === 'PENDING').length
      ) {
        this.selectAll = false
      }
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init(force) {
      this.loading = true

      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'WEEKS,CURRENT_WEEK' },
      })
      this.constants = respConstants.data

      if (localStorage.LEONETMARCEL_PERIOD) {
        this.period = localStorage.LEONETMARCEL_PERIOD
      } else {
        this.period = this.constants.CURRENT_WEEK.start_at
      }

      const week = this.getPeriod(this.period)
      this.filters.start_at = week.start_at
      this.filters.end_at = week.end_at

      this.syncUserSelection(force)
    },
    async syncUserSelection(force) {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        await this.$http.post(
          `/productions/tasks/sync-user-selection/`,
          { start_at: this.filters.start_at, force },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.getTasks()
        this.getselections()
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async getTasks() {
      const respMetrics = await this.$http.get('/users/selections/metrics/', {
        params: { start_at: this.filters.start_at },
      })
      this.metrics = respMetrics.data

      const resp = await this.$http.get('/productions/tasks/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': productionsTasksFields,
        },
      })
      this.tasks = resp.data.results
      this.meta = resp.data.meta

      this.loading = false
      this.$forceUpdate()
      this.scrollToTop()
    },
    async getselections() {
      const resp = await this.$http.get('/users/selections/', {
        params: {
          start_at: this.filters.start_at,
          per_page: 100,
          state: this.selectionsState,
          subscription_status: [
            'ENABLED',
            'CANCEL_AT_PERIOD_END',
            'PAUSE',
            'CANCELED',
          ],
        },
        headers: {
          'X-Fields': userSelectionFields,
        },
      })
      this.selections = resp.data.results
      this.selectionsMeta = resp.data.meta
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate() {
      this.displayForm = false
      this.init()
    },
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.filters, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    getPeriod(period) {
      let week
      if (period) {
        const weeks = this.constants.WEEKS.filter((w) => w.start_at === period)
        if (weeks.length === 1) {
          return weeks[0]
        }
      }
      return week
    },
  },
}
</script>

<style></style>
