<template>
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="px-1">Type</th>
          <th class="px-1">Fabrication</th>
          <th class="text-center px-1">Statut</th>
          <th class="text-center px-1">Quantité</th>
          <th class="text-right px-1">Livraison</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="task in tasks">
          <tr
            :key="task.id"
            :class="{ 'bg-light': tasks.includes(task.id) == true }"
          >
            <td class="px-1">
              <small class="badge badge-light text-info">
                {{ $t(`PRODUCTION.${task.source}`) }}
              </small>
            </td>
            <td class="px-1">
              <h5>
                <router-link :to="`/productions/tasks/${task.id}`">
                  {{ task.recipe.label }}
                </router-link>
              </h5>
            </td>
            <td class="text-center px-1">
              <router-link
                v-if="task.production_batch_id"
                :to="{
                  name: 'productionBatch',
                  params: { id: task.production_batch_id },
                }"
              >
                <span class="text-nowrap">
                  {{ $t(`PRODUCTION.${task.status}`) }}
                </span>
              </router-link>
              <span v-else class="text-nowrap">
                {{ $t(`PRODUCTION.${task.status}`) }}
              </span>
            </td>
            <td class="text-center px-1">{{ task.quantity }}</td>
            <td class="text-right px-1">
              {{ $dayjs(task.due_at).format('DD.MM.YYYY') }}
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="displayTaskTotal == true">
        <tr>
          <td class="text-center px-1"></td>
          <td class="text-center px-1"></td>
          <td class="text-right px-1"><b>Total</b></td>
          <td class="text-center px-1">
            <b>{{ tasksTotal }}</b>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ProductionTasks',
  components: {},
  inheritAttrs: false,
  props: {
    tasks: {
      type: Array,
      default() {
        return []
      },
    },
    displayTaskTotal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    tasksTotal() {
      let result = 0
      this.tasks.forEach((task) => {
        result += task.quantity
      })
      return result
    },
  },
  methods: {},
}
</script>
