<template>
  <div>
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <th>Client</th>
          <th>Formule</th>
          <th class="text-right">Sélection</th>
          <th class="text-right">État</th>
          <th class="text-right">Le</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="selection in selections" :key="selection.id">
          <td>
            <router-link :to="`/users/${selection.user.id}`">
              {{ selection.user.fullname }}
            </router-link>
          </td>
          <td>
            <router-link
              :to="`/subscriptions/${selection.user_subscription_id}`"
            >
              <span class="text-nowrap">
                Formule {{ selection.limit }} plats
              </span>
              <br />
              <span :title="selection.subscription.payment_service">
                {{ $t(`SUBSCRIPTION.${selection.subscription.status}`) }}
              </span>
            </router-link>
          </td>
          <td class="text-right">
            <span class="text-nowrap">
              {{ $t(`SELECTION.${selection.choice}`) }}
              ({{ selection.recipes_relations_quantities }}/{{
                selection.limit
              }})
            </span>
          </td>
          <td class="text-right">
            <span
              v-if="selection.recipes_relations_quantities == 0"
              class="text-warning"
            >
              Aucune
            </span>
            <span
              v-if="
                selection.recipes_relations_quantities > 0 &&
                selection.recipes_relations_quantities < selection.limit
              "
              class="text-secondary"
            >
              Partielle
            </span>
            <span
              v-if="selection.recipes_relations_quantities == selection.limit"
              class="text-success"
            >
              Complète
            </span>
          </td>
          <td
            class="text-right"
            :title="$dayjs(selection.updated_at).format('DD/MM/YYYY à HH:mm')"
          >
            {{ $dayjs(selection.updated_at).format('DD/MM/YYYY') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ProductionSelections',
  components: {},
  inheritAttrs: false,
  props: {
    selections: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
